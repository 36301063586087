<template>
    <div class="">
        <div class="">
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <section class="col-lg-12  connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                            <div class="card">
                                <div class="card-body table-responsive  table-head">
                                    <div class="row bor-bot">
                                    <div class="col-md-8 pl-0">
                                       <h1 class="m-0 text-dark pt-2 pb-4">Family Registrations List</h1>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                            </ol>
                                    </div>
                                    </div>
                                  </div>
                                    <div class="table-responsive mt-3">
                                      <div class="data-tablebutn pb-3">
                                        <div class="form-group">
                                          <select id="family_relation" class="form-control" v-model="filter_relation" @change="changeRelation">
                                            <option value="">All</option>
                                            <option value="1">HOH</option>
                                            <option value="2">Spouse</option>
                                            <option value="3">Child</option>
                                            <option value="4" v-if="subdomain=='shooting' ">Dependent</option>
                                          </select>
                                        </div>
                                      </div>
                                      <table class="table table-sm table-bordered table-hover" id="list_table">
                                          <thead class="thead-dark">
                                          <tr>
                                              <th>Member ID</th>
                                              <th>First Name</th>
                                              <th>Last Name</th>
                                              <th>Name</th>
                                              <th>E-Mail</th>
                                              <th>DOB</th>
                                              <th>Gender</th>
                                              <th>Magazine Subscription</th>
                                              <th>Relation</th>
                                              <th>Status</th>
                                              <th>Registered</th>
                                              <th>Expiration On</th>
                                              <th>Waiver Signed On</th>
                                              <th>Waiver Expiration On</th>
                                              <th>Transaction id</th>
                                              <th>Region</th>
                                              <th>Coupon Code</th>
                                              <th>Actions</th>
                                              <th>Transaction Date</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          </tbody>
                                      </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </div>
 
    </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';

export default {
    name: 'registrations',
  components: {},
    data() {
      return{
        table:'',
        filter_relation: ''
      }
    },
    methods: {
        formatDate(value)
        {
            var date = moment(value, "YYYY-MM-DD hh:mm:ss");
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
      deleteFamilyRegistration(id){
        if(confirm("Are you sure you want to delete?")){
          fetch(process.env.VUE_APP_URL + "api/registrations/delete/"+id,{
            method:'delete',
            headers: this.adminHeaders,
          })
              .then(res => res.json())
              .then(data => {
                this.alertMessage = "Record deleted successfully";
                this.displayAlert = true;
                this.table.draw();
                //  dt.row($(this).parents('tr')).remove().draw(true);
              })
              .catch(err => console.log(err));
        }
      },
      changeRelation(event){
        this.table.draw();
      }
    },
    beforeMount(){
      let relation = this.$route.query.relation;
      if(relation != undefined){
        this.filter_relation = relation;
      }
    },
    mounted(){
      window.reg=this;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        this.table = $("#list_table").DataTable({
            sDom: "Rlfrtip",
            processing: true,
            serverSide: true,
            pageLength: 10,            
            rowReorder: {
            selector: 'td:nth-child(2)'
            },
            responsive: true,
            ajax: {
              //url: process.env.VUE_APP_URL + "api/dashboard/registrations/list/Family",
              url: process.env.VUE_APP_URL + "api/admin/get_family_registrations",
              type: 'post',
              headers:{
                "Authorization":"Bearer "+localStorage.getItem('admintoken')
              },
              data: function (d) {
                d.relation = window.reg.filter_relation;
              }
            },
            columns: [
                { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
                { data: "first_name", name: "personal_details.first_name", visible: false, render: (data)=>{return data || "N/A";} },
                { data: "last_name", name: "personal_details.last_name", visible: false, render: (data)=>{return data || "N/A";} },
                { data: "full_name", name: "personal_details.first_name", render: (data)=>{return data || "N/A";} },
                { data: "email", name: "contact_details.email", render: (data)=>{return  '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>'|| "N/A";}},
                { data: "dob", name: "personal_details.dob", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
                { data: "gender", name: "personal_details.gender", render: (data)=>{return data || "N/A";} },
                { data: "printed_magazine", name: "Magazine Subscription", searchable:false, visible: this.subdomain == "waterski",  orderable:false, render: (data)=>{return (data == 0 || data == null)?'Yes':'No' || "N/A"}  },
                { data: "relation", name: "personal_details.relation" },
                { data: "status_text", name: "status_text", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
                { data: "start_date", name: "start_date", render: (data)=>{return this.formatDate(data)}},
                { data: "expired_at", name: "expired_at", render: (data)=>{return this.formatDate(data)} },
                { data: "waiver_signed_at", name: "waiver_signed_at", render: (data)=>{return data || "N/A";} },
                { data: "waiver_expired_at", name: "waiver_expired_at", render: (data)=>{return data || "N/A";} },
                { data: "transaction_id", name: "transaction_id", render: (data)=>{return data || "N/A";} },
                { data: "region_code", name: "region.code", render: (data)=>{return data || "N/A";}, visible: this.subdomain != "shooting", searchable: false },
                { data: "coupon_code", name: "coupon_code", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "waterski" },
                { data: "actions", name: "actions", orderable: false, searchable: false, render: (data,type,row,meta) => {
                    let btn = '';
                    if(this.getSiteProps('general.scoring')){
                        btn += `<a class="primary-text" href="/admin/member/${row.confirmation_code}/score/${row.id}" title="Shooting Score"><i class="fas fa-trophy"></i></a> `
                    }
                    btn += `<a  href="/admin/family-registrations/${row.id}"><i class="fas fa-eye"></i></a> <a class="action-family-delete" data-id="${row.regid}"   href="javascript:void(0);" ><i class="fas fa-trash"></i></a>`
                    return btn
                  }
                },
                { data: "transaction_date", name: "transaction_date", searchable: false, visible: false,render: (data)=>{return data || "N/A";} },
            ],
            columnDefs: [
                this.subdomain == 'waterski'?{ targets: 10, orderable: false, searchable: false }:{ targets: 9, orderable: false, searchable: false },
                this.subdomain == 'waterski'?{ targets: 15, visible: this.subdomain == "waterski" }:{ targets: 14, visible: this.subdomain == "waterski" },
            ],
            // infoCallback: function( settings, start, end, max, total, pre ) {
            //   var api = this.api();
            //   var pageInfo = api.page.info();
          
            //   return 'Page2 '+ (pageInfo.page+1) +' of '+ pageInfo.pages;
            // },
            order: this.subdomain == 'waterski'?[[17, "desc"]]:[[16, "desc"]]
        });
          var vueRouter = this.$router;
        document.addEventListener("click", function(e){
            if(e.target && e.target.parentNode.className == "actions-click"){
                var id = e.target.parentNode.getAttribute('data-id');
                vueRouter.push('/admin/family-registrations/'+ id);
            }
          if(e.target && e.target.parentNode.className == "action-family-delete"){
            var deleteId = e.target.parentNode.getAttribute('data-id');
            window.reg.deleteFamilyRegistration(deleteId);
          }
        });

    },
}
</script>